import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel/access-point-based`;

export const accessPointsRoutes = {
  'app.accessPointBased':   `${prefix}`,
  'app.accessPoints':   `${prefix}/access-points`,
  'app.accessPoints.create': `${prefix}/access-points/create`,
  'app.accessPoint':      (cId=':accessPointId') => `${prefix}/access-points/${cId}`,
  'app.accessPoints.edit': (cId=':accessPointId') => `${prefix}/access-points/${cId}/edit`,
};
