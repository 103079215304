import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel/measure-templates`;


export const measureTemplates = {
  'app.measureTemplateBased': `${prefix}/`,
  //
  'app.measureTemplateGroups':       `${prefix}/template-groups`,
  'app.measureTemplateGroup.create': `${prefix}/template-groups/create`,
  'app.measureTemplateGroup.edit': (mId=':measureTemplateGroupId') => `${prefix}/template-groups/${mId}/edit`,
  'app.measureTemplateGroup':      (mId=':measureTemplateGroupId') => `${prefix}/template-groups/${mId}`,
  //
  'app.measureTemplates':       `${prefix}/templates`,
  'app.measureTemplate.create': `${prefix}/templates/create`,
  'app.measureTemplate.edit': (eId=':measureTemplateId') => `${prefix}/templates/${eId}/edit`,
  'app.measureTemplate':      (eId=':measureTemplateId') => `${prefix}/templates/${eId}`,
}
