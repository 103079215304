import React from "react"
import {
  BoxSeam,
  Briefcase,
  CardChecklist,
  CardList,
  FileEarmarkText,
  House,
  Lightning,
  ListTask,
  People,
  Person,
  Tools,
  ChatLeft,
  Gear,
  BroadcastPin,
  PhoneVibrate
} from "react-bootstrap-icons";
import {route} from "app/router/urls/routes";
import {PERMISSION_COMPANY_ADMIN, PERMISSION_EMPLOYEE} from "app/config/permissions";


const navigationConfig = (t = () => '') => [
  {
    id: "home",
    title: 'Dashboard',
    type: "item",
    icon: <House size={20} />,
    navLink: route['app.dashboard'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "chat",
    title: 'Czat',
    type: "item",
    icon: <ChatLeft size={20} />,
    navLink: route['app.chat'],
    permissions: [PERMISSION_EMPLOYEE],
  },
  {
    id: "integrations",
    title: 'Integracje',
    type: "item",
    icon: <Gear size={20} />,
    navLink: route['app.integrations'],
    permissions: [PERMISSION_EMPLOYEE],
  },
  {
    type: "groupHeader",
    groupTitle: "Zasoby",
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "employees",
    title: 'Pracownicy',
    type: "item",
    icon: <Person size={20} />,
    navLink: route['app.team'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "positions",
    title: 'Stanowiska',
    type: "item",
    icon: <People size={20} />,
    navLink: route['app.positionBased'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: 'equipment',
    title: 'Sprzęt',
    type: "item",
    icon: <Tools size={20} />,
    navLink: route['app.toolBased'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "resources",
    title: 'Magazyn',
    type: "item",
    icon: <BoxSeam size={20} />,
    navLink: route['app.resourceBased'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "taskFieldCodes",
    title: 'Lista kodów',
    type: "item",
    icon: <CardList size={20} />,
    navLink: route['app.taskFieldCodes'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "accessPoints",
    title: 'Punkty dostępu',
    type: "item",
    icon: <BroadcastPin size={20} />,
    navLink: route['app.accessPoints'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "sensors",
    title: 'Sensory',
    type: "item",
    icon: <PhoneVibrate size={20} />,
    navLink: route['app.sensors'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    type: "groupHeader",
    groupTitle: "Projekty",
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "projects",
    title: 'Projekty',
    type: "item",
    icon: <CardChecklist size={20} />,
    navLink: route['app.projects'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "effectivity",
    title: 'Szablony efektywności',
    type: "item",
    icon: <Lightning size={20} />,
    navLink: route['app.measureTemplateBased'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "dailyReports",
    title: 'Raporty dzienne',
    type: "item",
    icon: <FileEarmarkText size={20} />,
    navLink: route['app.dailyReports'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "monthlyReports",
    title: 'Raporty miesięczne',
    type: "item",
    icon: <FileEarmarkText size={20} />,
    navLink: route['app.monthlyReports'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "projectTemplates",
    title: 'Szablony projektów',
    type: "item",
    icon: <FileEarmarkText size={20} />,
    navLink: route['app.projectTemplates'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "taskTemplates",
    title: 'Szablony zadań',
    type: "item",
    icon: <FileEarmarkText size={20} />,
    navLink: route['app.taskTemplates'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    type: "groupHeader",
    groupTitle: "Firma",
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "my-company.tasks",
    title: 'Moje zadania',
    type: "item",
    icon: <ListTask size={20} />,
    navLink: route['app.loggedUser.tasks'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
  {
    id: "my-company",
    title: 'Moja firma',
    type: "item",
    icon: <Briefcase size={20} />,
    navLink: route['app.myCompany'],
    permissions: [PERMISSION_COMPANY_ADMIN],
  },
]

export default navigationConfig
