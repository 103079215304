import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;

export const projectLocations = {
  'app.projectLocations':     (pId=':projectId') =>  `${prefix}/projects/${pId}/locations`,
  'app.projectLocation.add':  (pId=':projectId') =>  `${prefix}/projects/${pId}/locations/add`,
  'app.projectLocation':      (pId=':projectId', lId=':projectLocationId') =>  `${prefix}/projects/${pId}/locations/${lId}`,
  'app.projectLocation.edit': (pId=':projectId', lId=':projectLocationId') =>  `${prefix}/projects/${pId}/locations/${lId}/edit`,
}
