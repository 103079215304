import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;

export const positionsRoutes = {
  'app.positionBased':       `${prefix}/position-based/`,
  //
  'app.positionGroups':       `${prefix}/position-based/position-groups`,
  'app.positionGroup.create': `${prefix}/position-based/position-groups/create`,
  'app.positionGroup.edit': (eId=':positionGroupId') => `${prefix}/position-based/position-groups/${eId}/edit`,
  'app.positionGroup':      (eId=':positionGroupId') => `${prefix}/position-based/position-groups/${eId}`,
  //
  'app.positions':       `${prefix}/position-based/positions`,
  'app.position.create': `${prefix}/position-based/positions/create`,
  'app.position.edit': (eId=':positionId') => `${prefix}/position-based/positions/${eId}/edit`,
  'app.position':      (eId=':positionId') => `${prefix}/position-based/positions/${eId}`,
};
