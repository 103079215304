
export const reducerLoginWithEmail = (state, { userToken, userId, userName, userRoles, userEmail }) => {


  return {
    ...state,
    userToken,
    userRoles,
    user: {
      ...state.user,
      userId,
      userName,
      userRoles,
      userEmail,
    }
  }
}
