import { BASE_URL_API } from "app/config/env";
import { projectTemplates } from "app/mocks/projectTemplate";
import { projectTemplateCategories } from "app/mocks/projectTemplateCategories";
import { taskTemplates } from "app/mocks/taskTemplates";
import axios from "axios";
import MockAdapter from "axios-mock-adapter";

function getRandomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export const setupMocks = () => {
  const mock = new MockAdapter(axios, {
    onNoMatch: "passthrough",
    // delayResponse: 1000,
  });

  const singleProjectTemplate = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)`,
  );
  const projectTemplateTasks = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)/tasks`,
  );
  const projectTemplateTasksNotAssigned = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)/not-assinged-tasks`,
  );
  const projectTemplateAddTask = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)/add-task-to-project-template`,
  );
  const projectTemplateDeleteTask = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)/delete-task-from-project-template`,
  );
  const singleProjectTemplateCategory = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-template-categories/([0-9]+)`,
  );
  const singleTaskTemplate = new RegExp(
    `${BASE_URL_API}/v1/company-panel/task-templates/([0-9]+)`,
  );
  const projectAccessPoints = new RegExp(
    `${BASE_URL_API}/v1/company-panel/projects/([0-9]+)/devices/access-points`,
  );
  const projectTemplatesByCategory = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-template-categories/([0-9]+)/project-templates`,
  );
  const projectTemplateAddEquipment = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)/add-equipment-to-project-template`,
  );
  const projectTemplateEquipment = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)/equipment`,
  );
  const projectTemplateDeleteEquipment = new RegExp(
    `${BASE_URL_API}/v1/company-panel/project-templates/([0-9]+)/delete-equipment-from-project-template`,
  );

  // Project Templates
  mock
    .onGet(`${BASE_URL_API}/v1/company-panel/project-templates`)
    .reply((config) => {
      return [
        200,
        {
          data: projectTemplates,
        },
      ];
    });

  mock
    .onPost(`${BASE_URL_API}/v1/company-panel/project-templates`)
    .reply((config) => {
      const projectTemplate = JSON.parse(config.data);
      const projectTemplateId = +Math.random().toString().slice(2, 7);
      projectTemplates.push({
        id: projectTemplateId,
        name: projectTemplate?.name,
        description: projectTemplate?.description,
        projectTemplateCategoryId: projectTemplate?.projectTemplateCategoryId,
      });
      localStorage.setItem(
        "projectTemplates",
        JSON.stringify(projectTemplates),
      );
      return [201, { projectTemplateId }];
    });

  mock.onGet(projectTemplateTasks).reply((config) => {
    const projectTemplateId = config?.url?.match(projectTemplateTasks)?.[1];
    const projectTemplate = projectTemplates.find(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    if (!projectTemplate) return [400];
    const tasks = taskTemplates.filter((taskTemplate) =>
      projectTemplate?.taskTemplates?.includes(Number(taskTemplate.id)),
    );
    return [200, tasks];
  });

  mock.onGet(projectTemplateTasksNotAssigned).reply((config) => {
    const projectTemplateId = config?.url?.match(
      projectTemplateTasksNotAssigned,
    )?.[1];
    const projectTemplate = projectTemplates.find(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    const tasks = taskTemplates.filter(
      (taskTemplate) =>
        !projectTemplate?.taskTemplates?.includes(taskTemplate.id),
    );
    console.log(tasks);
    return projectTemplate ? [200, tasks] : [400];
  });

  mock.onPatch(projectTemplateDeleteTask).reply((config) => {
    const projectTemplateId = config?.url?.match(
      projectTemplateDeleteTask,
    )?.[1];
    const projectTemplateIndex = projectTemplates.findIndex(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    const data = JSON.parse(config.data);
    projectTemplates[projectTemplateIndex].taskTemplates = projectTemplates[
      projectTemplateIndex
    ].taskTemplates.filter(
      (taskTemplateId) => taskTemplateId !== Number(data.taskTemplateId),
    );
    localStorage.setItem("projectTemplates", JSON.stringify(projectTemplates));
    return [200];
  });

  mock.onPatch(projectTemplateDeleteEquipment).reply((config) => {
    const projectTemplateId = config?.url?.match(
      projectTemplateDeleteEquipment,
    )?.[1];
    const projectTemplateIndex = projectTemplates.findIndex(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    const data = JSON.parse(config.data);
    projectTemplates[projectTemplateIndex].equipmentItems = projectTemplates[
      projectTemplateIndex
    ].equipmentItems.filter(
      (equipmentItemId) => equipmentItemId !== Number(data.equipmentItemId),
    );
    localStorage.setItem("projectTemplates", JSON.stringify(projectTemplates));
    return [200];
  });

  mock.onPatch(projectTemplateAddTask).reply((config) => {
    const projectTemplateId = config?.url?.match(projectTemplateAddTask)?.[1];
    const projectTemplateIndex = projectTemplates.findIndex(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    const data = JSON.parse(config.data);
    console.log(projectTemplateId, data);
    console.log(projectTemplates[projectTemplateIndex]);
    if (!projectTemplates[projectTemplateIndex].taskTemplates) {
      projectTemplates[projectTemplateIndex].taskTemplates = [];
    }
    if (
      !projectTemplates[projectTemplateIndex].taskTemplates.includes(
        Number(data.taskTemplateId),
      )
    ) {
      projectTemplates[projectTemplateIndex].taskTemplates.push(
        Number(data.taskTemplateId),
      );
    }
    localStorage.setItem("projectTemplates", JSON.stringify(projectTemplates));
    return [200];
  });

  mock.onGet(projectTemplateEquipment).reply(async (config) => {
    const projectTemplateId = config?.url?.match(projectTemplateEquipment)?.[1];
    const projectTemplate = projectTemplates.find(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    if (!projectTemplate) return [400];
    const equipment = await axios.get(
      `${BASE_URL_API}/v1/company-panel/equipment-items`,
    );
    const projectTemplateEquipmentItems = equipment.data.data.filter(
      (equipmentItem) =>
        projectTemplate.equipmentItems.includes(Number(equipmentItem.id)),
    );
    return [200, projectTemplateEquipmentItems];
  });

  mock.onGet(singleProjectTemplate).reply((config) => {
    const projectTemplateId = config?.url?.match(singleProjectTemplate)?.[1];
    const projectTemplate = projectTemplates.find(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    return projectTemplate ? [200, projectTemplate] : [404];
  });

  mock.onPut(singleProjectTemplate).reply((config) => {
    const data = JSON.parse(config.data);
    const projectTemplateId = config?.url?.match(singleProjectTemplate)?.[1];
    const projectTemplate = projectTemplates.findIndex(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    if (projectTemplate === -1) {
      return [404];
    } else {
      const newData = { ...projectTemplates[projectTemplate], ...data };
      projectTemplates[projectTemplate] = newData;
      localStorage.setItem(
        "projectTemplates",
        JSON.stringify(projectTemplates),
      );
      return [200, newData];
    }
  });

  mock.onDelete(singleProjectTemplate).reply((config) => {
    const params = config?.url?.match(singleProjectTemplate);
    const projectTemplateId = params?.[1];
    const projectTemplateIndex = projectTemplates.findIndex(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    if (projectTemplateIndex === -1) {
      return [404];
    } else {
      projectTemplates.splice(projectTemplateIndex, 1);
      localStorage.setItem(
        "projectTemplates",
        JSON.stringify(projectTemplates),
      );
      return [200];
    }
  });

  mock.onPatch(projectTemplateAddEquipment).reply((config) => {
    const projectTemplateId = config?.url?.match(
      projectTemplateAddEquipment,
    )?.[1];
    const projectTemplateIndex = projectTemplates.findIndex(
      (projectTemplate) => projectTemplate.id === Number(projectTemplateId),
    );
    const data = JSON.parse(config.data);
    console.log(projectTemplateId, data);
    if (
      !projectTemplates[projectTemplateIndex].equipmentItems.includes(
        Number(data.equipmentItemId),
      )
    ) {
      projectTemplates[projectTemplateIndex].equipmentItems.push(
        Number(data.equipmentItemId),
      );
    }
    localStorage.setItem("projectTemplates", JSON.stringify(projectTemplates));
    return [200];
  });

  // Projct Template Categories
  mock
    .onGet(`${BASE_URL_API}/v1/company-panel/project-template-categories/list`)
    .reply((config) => {
      const projectTemplateCategoriesToReturn = projectTemplateCategories.map(
        ({ id, name }) => ({
          id,
          name,
        }),
      );
      return [200, projectTemplateCategoriesToReturn];
    });

  mock
    .onGet(`${BASE_URL_API}/v1/company-panel/project-template-categories`)
    .reply((config) => {
      return [
        200,
        {
          data: projectTemplateCategories,
        },
      ];
    });

  mock
    .onPost(`${BASE_URL_API}/v1/company-panel/project-template-categories`)
    .reply((config) => {
      const projectTemplateCategory = JSON.parse(config.data);
      const projectTemplateCategoryId = +Math.random().toString().slice(2, 7);
      projectTemplateCategories.push({
        id: projectTemplateCategoryId,
        name: projectTemplateCategory?.name,
        description: projectTemplateCategory?.description,
      });
      localStorage.setItem(
        "projectTemplates",
        JSON.stringify(projectTemplateCategories),
      );
      return [201, { projectTemplateCategoryId }];
    });

  mock.onGet(projectTemplatesByCategory).reply((config) => {
    const categoryId = config?.url?.match(projectTemplatesByCategory)?.[1];
    const templatesInCategory = projectTemplates.filter(
      (template) => template.projectTemplateCategoryId === Number(categoryId),
    );
    return [200, templatesInCategory];
  });

  mock.onGet(singleProjectTemplateCategory).reply((config) => {
    const projectTemplateId = config?.url?.match(
      singleProjectTemplateCategory,
    )?.[1];
    const projectTemplateCategory = projectTemplateCategories.find(
      (projectTemplateCategory) =>
        projectTemplateCategory.id === Number(projectTemplateId),
    );
    return projectTemplateCategory ? [200, projectTemplateCategory] : [404];
  });

  mock.onPut(singleProjectTemplateCategory).reply((config) => {
    const data = JSON.parse(config.data);
    const projectTemplateCategoryId = config?.url?.match(
      singleProjectTemplateCategory,
    )?.[1];
    const projectTemplateCategory = projectTemplateCategories.findIndex(
      (projectTemplateCategory) =>
        projectTemplateCategory.id === Number(projectTemplateCategoryId),
    );
    if (projectTemplateCategory === -1) {
      return [404];
    } else {
      const newData = {
        ...projectTemplateCategories[projectTemplateCategory],
        ...data,
      };
      projectTemplateCategories[projectTemplateCategory] = newData;
      localStorage.setItem(
        "projectTemplateCategories",
        JSON.stringify(projectTemplateCategories),
      );
      return [200, newData];
    }
  });

  mock.onDelete(singleProjectTemplateCategory).reply((config) => {
    const params = config?.url?.match(singleProjectTemplateCategory);
    const projectTemplateCategoryId = params?.[1];
    const projectTemplateCategoryIndex = projectTemplateCategories.findIndex(
      (projectTemplateCategory) =>
        projectTemplateCategory.id === Number(projectTemplateCategoryId),
    );
    if (projectTemplateCategoryIndex === -1) {
      return [404];
    } else {
      projectTemplateCategories.splice(projectTemplateCategoryIndex, 1);
      localStorage.setItem(
        "projectTemplateCategories",
        JSON.stringify(projectTemplateCategories),
      );
      return [200];
    }
  });

  // Task Templates
  mock
    .onGet(`${BASE_URL_API}/v1/company-panel/task-templates`)
    .reply((config) => {
      return [
        200,
        {
          data: taskTemplates,
        },
      ];
    });

  mock
    .onPost(`${BASE_URL_API}/v1/company-panel/task-templates`)
    .reply((config) => {
      const taskTemplate = JSON.parse(config.data);
      const taskTemplateId = +Math.random().toString().slice(2, 7);
      taskTemplates.push({
        id: taskTemplateId,
        name: taskTemplate?.name,
        description: taskTemplate?.description,
      });
      localStorage.setItem("taskTemplates", JSON.stringify(taskTemplates));
      return [201, { taskTemplateId }];
    });

  mock.onGet(singleTaskTemplate).reply((config) => {
    const projectTemplateId = config?.url?.match(singleTaskTemplate)?.[1];
    const taskTemplate = taskTemplates.find(
      (taskTemplate) => taskTemplate.id === Number(projectTemplateId),
    );
    return taskTemplate ? [200, taskTemplate] : [404];
  });

  mock.onPut(singleTaskTemplate).reply((config) => {
    const data = JSON.parse(config.data);
    const taskTemplateId = config?.url?.match(singleTaskTemplate)?.[1];
    const taskTemplateIndex = taskTemplates.findIndex(
      (taskTemplate) => taskTemplate.id === Number(taskTemplateId),
    );
    if (taskTemplateIndex === -1) {
      return [404];
    } else {
      const newData = { ...taskTemplates[taskTemplateIndex], ...data };
      taskTemplates[taskTemplateIndex] = newData;
      localStorage.setItem("taskTemplates", JSON.stringify(taskTemplates));
      return [200, newData];
    }
  });

  mock.onDelete(singleTaskTemplate).reply((config) => {
    const params = config?.url?.match(singleTaskTemplate);
    const taskTemplateId = params?.[1];
    const taskTemplateIndex = taskTemplates.findIndex(
      (taskTemplate) => taskTemplate.id === Number(taskTemplateId),
    );
    if (taskTemplateIndex === -1) {
      return [404];
    } else {
      taskTemplates.splice(taskTemplateIndex, 1);
      localStorage.setItem("taskTemplates", JSON.stringify(taskTemplates));
      return [200];
    }
  });

  mock.onGet(projectAccessPoints).reply(async (config) => {
    const projectId = config?.url?.match(projectAccessPoints)?.[1];
    try {
      const { data } = await axios.get(
        `${BASE_URL_API}/v1/company-panel/projects/${projectId}/devices/sensors`,
      );
      const accessPoints = data.data.reduce((arr, sensor) => {
        if (
          sensor.devicesAccessPoint &&
          arr.findIndex((ap) => ap.id === sensor.devicesAccessPoint.id) === -1
        ) {
          arr.push(sensor.devicesAccessPoint);
        }
        return arr;
      }, []);
      return [200, accessPoints];
    } catch (err) {
      return [400];
    }
  });

  mock
    .onGet(`${BASE_URL_API}/v1/company-panel/equipment-items`)
    .reply(async (config) => {
      const response = await fetch(
        `${BASE_URL_API}/v1/company-panel/equipment-items?${new URLSearchParams(config.params)}`,
        {
          headers: new Headers({
            Authorization: config.headers.Authorization,
          }),
        },
      );
      const { data, ...rest } = await response.json();
      const dataWithLocation = {
        ...rest,
        data: data.map((item) => ({
          ...item,
          locationLat: getRandomInRange(49, 51),
          locationLon: getRandomInRange(20, 22),
        })),
      };
      return [200, dataWithLocation];
    });
};
