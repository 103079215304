import {BASE_URL} from "app/config/env";
import {authRoutes} from "app/router/urls/common/auth";
import {employeesUrls} from "app/router/urls/app/employee/employees";
import {projectsUrls} from "app/router/urls/app/project/projects";
import {myCompanyUrls} from "app/router/urls/app/myCompany/myCompany";
import {dashboard} from "app/router/urls/app/dashboard/dashboard";
import {publicRoutes} from "app/router/urls/common/publicRoutes";
import {loggedUserUrls} from "app/router/urls/app/loggedUser/loggedUserUrls";
import {resourcesRoutes} from "app/router/urls/app/resources/resourcesRoutes";
import {positionsRoutes} from "app/router/urls/app/positions/positionsRoutes";
import {toolsRoutes} from "app/router/urls/app/tools/toolsRoutes";
import {measureTemplates} from "app/router/urls/app/efficiencyTemplates/measureTemplates";
import {reportsRoutes} from "app/router/urls/app/reports/reportsRoutes";
import {taskFieldCodes} from "./app/taskFieldCodes/taskFieldCodes";
import {chat} from 'app/router/urls/app/chat/chat';
import { integrations } from "./app/integrations/integrations";
import { statusReportsRoutes } from "./app/statusReports/statusReports";
import { sensorsRoutes } from "./app/sensors/sensorsRoutes";
import { accessPointsRoutes } from "./app/accessPoints/accessPointsRoutes";
import { projectTemplatesRoutes } from "./app/projectTemplates/projectTemplatesRoutes";
import { taskTemplatesRoutes } from "./app/taskTemplates/taskTemplatesRoutes";

const prefix = `${BASE_URL}/panel`;

export const route = {
  '/':   `${BASE_URL}/`,
  'app': `${BASE_URL}/`,
  'account': `${prefix}/account`,
  ...authRoutes,
  ...publicRoutes,
  ...employeesUrls,
  ...projectsUrls,
  ...measureTemplates,
  ...myCompanyUrls,
  ...reportsRoutes,
  ...loggedUserUrls,
  ...resourcesRoutes,
  ...taskFieldCodes,
  ...toolsRoutes,
  ...positionsRoutes,
  ...dashboard,
  ...chat,
  ...integrations,
  ...statusReportsRoutes,
  ...sensorsRoutes,
  ...accessPointsRoutes,
  ...projectTemplatesRoutes,
  ...taskTemplatesRoutes,
};
