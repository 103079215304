import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel/project-templates-based`;

export const projectTemplatesRoutes = {
  'app.projectTemplatesBased':       `${prefix}/`,
  //
  'app.projectTemplateCategories':       `${prefix}/project-template-categories`,
  'app.projectTemplateCategory':      (eId=':projectTemplateCategoryId') => `${prefix}/project-template-categories/${eId}`,
  //
  'app.projectTemplates':       `${prefix}/project-templates`,
  'app.projectTemplate':      (eId=':projectTemplateId') => `${prefix}/project-templates/${eId}`,
};
