import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;

export const employeesUrls = {
  // employees
  'app.team': `${prefix}/team`,
  'app.employees':    `${prefix}/team/employees`,
  'app.employee.add': `${prefix}/team/employees/add`,
  'app.employee':      (eId=':employeeId') => `${prefix}/team/employees/${eId}`,
  'app.employee.edit': (eId=':employeeId') => `${prefix}/team/employees/${eId}/edit`,
  // groups
  'app.employeeGroups':    `${prefix}/team/employee-groups`,
  'app.employeeGroup.add': `${prefix}/team/employee-groups/add`,
  'app.employeeGroup':      (gId=':employeeGroupId') => `${prefix}/team/employee-groups/${gId}`,
  'app.employeeGroup.edit': (gId=':employeeGroupId') => `${prefix}/team/employee-groups/${gId}/edit`,
}
