import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;

export const projectEmployees = {
  'app.projectEmployees':    (pId=':projectId') => `${prefix}/projects/${pId}/employees`,
  'app.projectEmployee.add': (pId=':projectId') => `${prefix}/projects/${pId}/employees/add`,
  'app.projectEmployee':     (pId=':projectId', tId=':projectGroupId') => `${prefix}/projects/${pId}/employees/${tId}`,
  //
  'app.projectGroups':     (pId=':projectId') => `${prefix}/projects/${pId}/employees/groups`,
  'app.projectGroup.add':  (pId=':projectId') => `${prefix}/projects/${pId}/employees/groups/add`,
  'app.projectGroup':      (pId=':projectId', tId=':projectGroupId') => `${prefix}/projects/${pId}/employees/groups/${tId}`,
  'app.projectGroup.employees':(pId=':projectId', tId=':projectGroupId') => `${prefix}/projects/${pId}/employees/groups/${tId}/employees`,
  'app.projectGroup.edit': (pId=':projectId', tId=':projectGroupId') => `${prefix}/projects/${pId}/employees/groups/${tId}/edit`,
}
