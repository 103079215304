import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;

export const ProjectRecords = {
  'app.projectRecords': (pId=':projectId') =>  `${prefix}/projects/${pId}/records`,
  //
  'app.projectLeaderReports': (pId=':projectId') =>  `${prefix}/projects/${pId}/records/reports`,
  'app.projectLeaderReport':  (pId=':projectId', rId=':reportId') =>  `${prefix}/projects/${pId}/records/reports/${rId}`,
  //
  'app.projectClocking': (pId=':projectId') =>  `${prefix}/projects/${pId}/records/clocking`,
  //
  'app.projectDailyReports': (pId=':projectId') =>  `${prefix}/projects/${pId}/records/daily-reports`,
}
