import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;


export const reportsRoutes = {
  'app.dailyReports': `${prefix}/daily-reports/`,
  'app.dailyReport':  (rId=':reportId') => `${prefix}/daily-reports/${rId}`,
  'app.monthlyReports': `${prefix}/monthly-reports/`,
  'app.monthlyReport':  (rId=':reportId') => `${prefix}/monthly-reports/${rId}`,
}
