import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;

export const authRoutes = {
  'auth.login':           `${prefix}/auth/login`,
  'auth.logout':          `${prefix}/logout`,
  'auth.register':        `${prefix}/auth/register`,
  'auth.unauthorized':    `${prefix}/auth/unauthorized`,
  'auth.password.reset':  `${prefix}/auth/password/reset`,
  'auth.password.forgot': `${prefix}/auth/password/forgot`,
};
