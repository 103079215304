import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel/tool-based`;

export const toolsRoutes = {
  'app.toolBased':   `${prefix}`,
  //
  'app.tools':       `${prefix}/tools`,
  'app.tool.create': `${prefix}/tools/create`,
  'app.tool':      (cId=':toolId') => `${prefix}/tools/${cId}`,
  'app.tool.edit': (cId=':toolId') => `${prefix}/tools/${cId}/edit`,
  //
  'app.toolGroups':       `${prefix}/tool-groups`,
  'app.toolGroup.create': `${prefix}/tool-groups/create`,
  'app.toolGroup':      (cId=':toolGroupId') => `${prefix}/tool-groups/${cId}`,
  'app.toolGroup.edit': (cId=':toolGroupId') => `${prefix}/tool-groups/${cId}/edit`,
};
