import {A__FAKE_DB_EDIT, A__FAKE_DB_REMOVE, A__FAKE_DB_SET} from "app/redux/@fakeDb/actionTypes/types";
import {reducerFakeElementSet} from "app/redux/@fakeDb/reducers/reducer_elementSet";
import {reducerFakeElementEdit} from "app/redux/@fakeDb/reducers/reducer_elementEdit";
import {reducerFakeElementRemove} from "app/redux/@fakeDb/reducers/reducer_elementRemove";

const initialState = () => ({
  employees: {
    one: {
      id: 'one',
      name: 'Jan Kowalski',
      email: 'janko@muzykant.pl',
      phoneNo: '+48 123 456 789',
      roles: []
    },
    two: {
      id: 'two',
      name: 'Adam Mickiewicz',
      email: 'adamos@połeta.pl',
      phoneNo: '+48 123 456 789',
      roles: []
    }
  },
  myCompany: {
    company: {
      name: 'Reacly fotowoltaika',
      address: 'ul. Jana Pawła II 00-867 Warszawa',
      email: 'biuro@reacly.pl',
      phoneNo: '500 400 435'
    }
  },
  projects: {
    test: {
      id: 'test',
      name: 'projekcik',
      workspaceId: 2
    }
  },
  roles: {
    one: {
      id: 1,
      name: "pracownik"
    },
    two: {
      id: 2,
      name: "kierownik"
    },
    three: {
      id: 3,
      name: "kierowca"
    },
    four: {
      id: 4,
      name: "menedżer"
    },
  },
  __totalNo__: 0,
});

export const fakeDbReducer = (state = initialState(), {type, payload}) => {

  switch (type) {
    case A__FAKE_DB_SET:
      return reducerFakeElementSet(state, payload);
    case A__FAKE_DB_EDIT:
      return reducerFakeElementEdit(state, payload);
    case A__FAKE_DB_REMOVE:
      return reducerFakeElementRemove(state, payload);
    default:
      return state;
  }
}
