let projectTemplateCategories = [
  {
    id: 2,
    name: 'Marketing',
    description: 'Kategoria dla projektów związanych z marketingiem.',
  },
  {
    id: 3,
    name: 'Rozwój oprogramowania',
    description: 'Kategoria dla projektów programistycznych.',
  },
  {
    id: 4,
    name: 'Zarządzanie zasobami ludzkimi',
    description: 'Kategoria dla projektów HR.',
  },
  {
    id: 5,
    name: 'Finanse',
    description: 'Kategoria dla projektów finansowych.',
  },
]


const projectTemplateCategoriesFromLocalStorage = localStorage.getItem('projectTemplateCategories')
if(projectTemplateCategoriesFromLocalStorage) {
  projectTemplateCategories = JSON.parse(projectTemplateCategoriesFromLocalStorage)
}

export { projectTemplateCategories };