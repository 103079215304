import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel`;

export const projectTasks = {
  'app.projectTasks':     (pId=':projectId') => `${prefix}/projects/${pId}/tasks`,
  'app.projectTask.add':  (pId=':projectId') => `${prefix}/projects/${pId}/tasks/add`,
  'app.projectTask':      (pId=':projectId', tId=':taskId') => `${prefix}/projects/${pId}/tasks/${tId}`,
  'app.projectTask.edit': (pId=':projectId', tId=':taskId') => `${prefix}/projects/${pId}/tasks/${tId}/edit`,
};
