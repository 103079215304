import { BASE_URL } from "app/config/env";
import { projectTasks } from "app/router/urls/app/project/projectTasks";
import { projectSections } from "app/router/urls/app/project/ProjectSections";
import { projectEmployees } from "app/router/urls/app/project/projectEmployees";
import { projectLocations } from "app/router/urls/app/project/projectLocations";
import { ProjectRecords } from "app/router/urls/app/project/projectRecords";
import { projectEquipment } from "app/router/urls/app/project/projectEquipment";
import { projectMaterials } from "app/router/urls/app/project/projectMaterials";
import { projectSensors } from "./projectSensors";
import { projectMap } from "./projectMap";

const prefix = `${BASE_URL}/panel`;

export const projectsUrls = {
  "app.projects": `${prefix}/projects`,
  "app.project": (pId = ":projectId") => `${prefix}/projects/${pId}`,
  "app.project.add": `${prefix}/projects/add`,
  "app.project.completeInformation": (pId = ":projectId") =>
    `${prefix}/projects/${pId}/complete-information`,
  "app.project.edit": (pId = ":projectId") => `${prefix}/projects/${pId}/edit`,
  ...projectTasks,
  ...projectEmployees,
  ...projectSections,
  ...projectLocations,
  ...ProjectRecords,
  ...projectMaterials,
  ...projectEquipment,
  ...projectSensors,
  ...projectMap,
};
