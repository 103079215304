let projectTemplates = [
  {
    id: 2,
    name: "Kampania marketingowa",
    description: "Szablon dla kampanii marketingowych.",
    projectTemplateCategoryId: 2,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 3,
    name: "Rozwój aplikacji mobilnej",
    description:
      "Szablon dla projektów dotyczących rozwoju aplikacji na telefony komórkowe.",
    projectTemplateCategoryId: 3,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 4,
    name: "Rekrutacja pracownika",
    description: "Szablon dla procesu rekrutacji.",
    projectTemplateCategoryId: 4,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 5,
    name: "Budżet roczny",
    description: "Szablon dla tworzenia rocznego budżetu firmy.",
    projectTemplateCategoryId: 5,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 6,
    name: "Promocja produktu",
    description: "Szablon dla promocji nowego produktu.",
    projectTemplateCategoryId: 2,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 7,
    name: "Optymalizacja SEO",
    description: "Szablon dla projektów związanych z SEO.",
    projectTemplateCategoryId: 2,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 8,
    name: "Wdrożenie nowej funkcji",
    description: "Szablon dla wdrożenia nowej funkcji w oprogramowaniu.",
    projectTemplateCategoryId: 3,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 9,
    name: "Badanie satysfakcji pracowników",
    description:
      "Szablon dla przeprowadzenia badania satysfakcji wśród pracowników.",
    projectTemplateCategoryId: 4,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 10,
    name: "Roczna analiza finansowa",
    description:
      "Szablon dla przeprowadzenia rocznej analizy finansowej firmy.",
    projectTemplateCategoryId: 5,
    equipmentItems: [],
    taskTemplates: [],
  },
  {
    id: 11,
    name: "Aktualizacja systemu płatności",
    description: "Szablon dla aktualizacji systemu płatności w aplikacji.",
    projectTemplateCategoryId: 3,
    equipmentItems: [],
    taskTemplates: [],
  },
];

const projectTemplatesFromLocalStorage = localStorage.getItem(
  "projectTemplates",
);
if (projectTemplatesFromLocalStorage) {
  projectTemplates = JSON.parse(projectTemplatesFromLocalStorage);
}

export { projectTemplates };
