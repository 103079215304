import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel/resource-based`;

export const resourcesRoutes = {
  'app.resourceBased':   `${prefix}`,
  //
  'app.resources':       `${prefix}/resources`,
  'app.resource.create': `${prefix}/resources/create`,
  'app.resource':      (rId = ':resourceId') => `${prefix}/resources/${rId}`,
  'app.resource.edit': (rId = ':resourceId') => `${prefix}/resources/${rId}/edit`,
  //
  'app.resourceGroups':       `${prefix}/resource-groups`,
  'app.resourceGroup.create': `${prefix}/resource-groups/create`,
  'app.resourceGroup':      (rId = ':resourceGroupId') => `${prefix}/resource-groups/${rId}`,
  'app.resourceGroup.edit': (rId = ':resourceGroupId') => `${prefix}/resource-groups/${rId}/edit`,
};
