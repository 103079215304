import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel/task-templates-based`;

export const taskTemplatesRoutes = {
  'app.taskTemplatesBased':       `${prefix}/`,
  //
  'app.taskTemplates':        `${prefix}/task-templates`,
  'app.taskTemplate':         (eId=':taskTemplateId') => `${prefix}/task-templates/${eId}`,
};
