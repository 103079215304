let taskTemplates = [
  {
    id: 1,
    name: "Ustalenie wymagań aplikacji",
    description:
      "Zebranie wymagań od klienta dotyczących funkcji i wyglądu aplikacji mobilnej.",
    projectTemplateId: 3,
  },
  {
    id: 2,
    name: "Projekt interfejsu użytkownika",
    description: "Tworzenie projektu UI/UX dla aplikacji mobilnej.",
    projectTemplateId: 3,
  },
  {
    id: 3,
    name: "Wybór technologii i narzędzi",
    description:
      "Decyzja o technologiach i narzędziach do wykorzystania w projekcie.",
    projectTemplateId: 3,
  },
  {
    id: 4,
    name: "Rozwój funkcji aplikacji",
    description: "Programowanie i implementacja głównych funkcji aplikacji.",
    projectTemplateId: 3,
  },
  {
    id: 5,
    name: "Integracja zewnętrznych serwisów",
    description:
      "Integracja aplikacji z zewnętrznymi serwisami i API, jeśli są wymagane.",
    projectTemplateId: 3,
  },
  {
    id: 6,
    name: "Testy jednostkowe",
    description:
      "Pisanie i uruchamianie testów jednostkowych dla funkcji aplikacji.",
    projectTemplateId: 3,
  },
  {
    id: 7,
    name: "Testy funkcjonalne",
    description:
      "Przeprowadzenie testów funkcjonalnych, aby sprawdzić poprawność działania aplikacji.",
    projectTemplateId: 3,
  },
  {
    id: 8,
    name: "Optymalizacja wydajności",
    description:
      "Prace nad poprawą wydajności aplikacji, optymalizacja zasobów.",
    projectTemplateId: 3,
  },
  {
    id: 9,
    name: "Przygotowanie do wdrożenia",
    description:
      "Zakładanie kont na sklepach aplikacji, konfiguracja serwerów i przygotowanie do publikacji.",
    projectTemplateId: 3,
  },
  {
    id: 10,
    name: "Publikacja i monitorowanie",
    description:
      "Publikowanie aplikacji w sklepach i monitorowanie jej działania oraz zbieranie opinii użytkowników.",
    projectTemplateId: 3,
  },
];

const taskTemplatesFromLocalStorage = localStorage.getItem("taskTemplates");
if (taskTemplatesFromLocalStorage) {
  taskTemplates = JSON.parse(taskTemplatesFromLocalStorage);
}

export { taskTemplates };
