import {BASE_URL} from "app/config/env";

const prefix = `${BASE_URL}/panel/sensor-based`;

export const sensorsRoutes = {
  'app.sensorBased':   `${prefix}`,
  'app.sensors':   `${prefix}/sensors`,
  'app.sensors.create': `${prefix}/sensors/create`,
  'app.sensor':      (cId=':sensorId') => `${prefix}/sensors/${cId}`,
  'app.sensors.edit': (cId=':sensorId') => `${prefix}/sensors/${cId}/edit`,
};
